<template>
  <div class="about">
    <Navbar />
    <Testimoni />
    <Subscribe />
    <Link />
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Testimoni from '@/components/Testimoni.vue'
import Subscribe from '@/components/Subscribe.vue'
import Link from '@/components/Link.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'About',
  components: {
    Navbar, 
    Testimoni, 
    Subscribe, 
    Link, 
    Footer
  }
}
</script>